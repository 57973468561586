import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import useStatistics from  "../../data/useStatistics";
import { Typography } from "../../ui";
import {
  Card, CardDashedGraph
} from "../../widgets";
import { MetricsStatistics } from "../../widgets/MetricsStatistics";
import { StatisticsSort } from "../../widgets/Sort";
import "./Statistics.scss";
import { useLocation } from "react-router-dom";
import { Box, Modal } from "@mui/material";
import { ReactComponent as Close } from "../../assets/images/close.svg";


const FORMIK_INITIAL_VALUES = {
  selected_campaigns: [],
  selected_goals: [],
  metric_counter: null,
  media_plan: {
    date_start: null,
    date_end: null,
  },
}

const FORMIK_INITIAL_VALUES_DATA = (camp, metric) => {
  return {
    selected_campaigns: [camp],
    selected_goals: [],
    metric_counter: metric,
    media_plan: {
      date_start: null,
      date_end: null,
    },
  }
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};


function Statistics() {
  const location = useLocation();
  const [err, setErr] = useState(false)
  const [modal, setModal] = useState(false)
  let campaign_id = location?.state?.campaign_id;
  let counter_id = location?.state?.counter_id;
  const [formikValues, setFormikValues] = useState(FORMIK_INITIAL_VALUES);
  let type = "day" 
  if ((formikValues.media_plan.date_end-formikValues.media_plan.date_start)/(1000 * 60 * 60 * 24) < 1) type = "hour"
  // if ((formikValues.media_plan.date_end-formikValues.media_plan.date_start)/(1000 * 60 * 60 * 24) > 31) type="week"
  
  let {data, error} = useStatistics(formikValues.metric_counter,formikValues.media_plan.date_start,
      formikValues.media_plan.date_end,formikValues.selected_campaigns,formikValues.selected_goals,type);
  useEffect(()=>{
    if(error && String(error).includes('Error: {"detail":"date_from не может быть раньше текущей даты более чем на 12 месяцев"}')){
      setErr(true)
    }else{
      setErr(false)
    }
  },[error])
  useEffect(()=>{
    if (err) {
      setModal(true)
  }
  },[err])
  if (!data?.metrics) {
    data = {
      "metrics": ["shows", "clicks", "spent", "ctr", "cpc", "ecpm", "cr", "cpa"],
      "time_intervals": [],
      "data": [],
      "totals": [0, 0, 0, 0, 0, 0, null, null]
    }
  }
  
  // console.log("updated", formikValues)
  return (
    <>
    <Formik
      initialValues={(campaign_id) ? FORMIK_INITIAL_VALUES_DATA(campaign_id, counter_id) : FORMIK_INITIAL_VALUES}
      onSubmit={(value) => {
        console.log('export') || console.log(value)
      }}
      innerRef={formikAction => {
        if (!formikAction) {
          return;
        }
        const { values } = formikAction;
        if (
          values.selected_campaigns.length === formikValues.selected_campaigns.length
          && values.selected_campaigns.every(
            (value, index) => value === formikValues.selected_campaigns[index]
          )
          && values.media_plan.date_start === formikValues.media_plan.date_start
          && values.media_plan.date_end === formikValues.media_plan.date_end
          && values.metric_counter === formikValues.metric_counter
          && values.selected_goals.length === formikValues.selected_goals.length
          && values.selected_goals.every(
            (value, index) => value === formikValues.selected_goals[index]
          )
        ) {
          return;
        }
        setFormikValues(formikAction.values);
      }}
    >
      <Form>
        <div className="Statistics">
          <Typography variant="h1" color="dark-gray">
            Статистика
          </Typography>
          <StatisticsSort />
          <div className="line"></div>
          <div className="container">
          <MetricsStatistics data={data?.totals} />
            {/* <InterestsStatistics data={InterestData} /> */}
            {/* <Devices data={fiterdata2} />
            <Geography data={fiterdata2} /> */}
          </div>
          <Card>
            <CardDashedGraph title type={type} chartData={data} budget={data.totals} />
          </Card>
        </div>
      </Form>
    </Formik>
    <Modal open={modal} onClose={() => setModal(false)}>
      <Box sx={style}>
        <h4 style={{width:"100%", textAlign:'center'}}>Cтатистика за более чем 12 месяца не доступна</h4>
      <Close onClick={() => setModal(false)} style={{position:'absolute', top:10,right:10, cursor:'pointer'}} />
      </Box>
    </Modal>
    </>
  );
}

export default Statistics;