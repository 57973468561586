import "./Header.scss";
import { Link } from "react-router-dom";
import { Notification, User } from "../";
import useLogout from "../../hooks/useLogout";

import { ReactComponent as LogoIcon } from "../../assets/images/logo.svg";
import { ReactComponent as ExitIcon } from "../../assets/images/exit.svg";
import useUser from "../../data/useUser";

export default function Header() {
  const logout = useLogout();
  const {
    user: {
      user_balance: { score },
    },
  } = useUser();
  function numberWithSpaces(number) {
    if(number < 10000) return number
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }
  return (
    <header className="Header">
      <div className="header-container">
        <Link className="icon" to="/">
          <LogoIcon />
        </Link>

        <div className="profile">
          <div className="wrapp">
            <div className="balance">Баланс:</div>
            <div className="sum">{numberWithSpaces(score) ?? 0} ₽</div>
          </div>

          {false && <Notification />}

          <User />

          <div className="exit">
            <button type="button" onClick={logout}>
              <ExitIcon />
            </button>
          </div>
        </div>
      </div>
    </header>
  );
}
