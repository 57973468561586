import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Label
} from "recharts";
import { Category } from "../";
import Card from '@mui/material/Card';

const LEGEND = [
  {
    key: "shows",
    new_key: "shows",
    name: "Показы",
    color: "#5dce5a",
    active: true,
  },
  {
    key: "clicks",
    new_key: "clicks",
    name: "Клики",
    color: "#eeb55d",
    active: false,
  },
  {
    key: "spent",//
    new_key: "spent",
    name: "Расход (₽)",
    color: "#8dbfea",
    active: false,
  },
  {
    key: "ctr",
    new_key: "ctr",
    name: "CTR %",
    color: "#a566d5",
    active: false,
  },
  {
    key: "cpc",
    new_key: "cpc",
    name: "CPC (₽)",
    color: "#f99d9d",
    active: false,
  },
  {
    key: "ecpm",//
    new_key: "ecpm",
    name: "eCPM ( ₽)",
    color: "#f4e75e",
    active: false,
  },
  {
    key: "cr",//
    new_key: "cr",
    name: "CR (%)",
    color: "#77797a",
    active: false,
  },
  {
    key: "cpa",//
    new_key: "cpa",
    name: "CPA",
    color: "#cccdcc",
    active: false,
  },
];

function CustomTooltip({  label, active, data, legend}) {
  let keys=[]
  let values=[]
  data.map((value,key) => (value?.date === label) ? Object.keys(data[key]).forEach(i => 
    {keys.push(i)
      values.push(data[key][i])}) : "")

  let categories = []
  let legend_color = Object.values(LEGEND)
  legend_color.map((item) => {
    if (item.name === legend[0]?.name) {
      return item.key="1"//"#3f73f9"
    }
    else if (item.name === legend[1]?.name) {
      return item.key="second"//"#f9143a"
    }
    else {
      return item.key="none"//"#42454c"
    }
  })
  for (let i=1; i < 9; i++){
    categories.push( 
      <Category
        current_item={(legend_color?.find(item => (item.new_key===keys[i]) ? item : ""))}
        withoutCheckbox={true}
        key={keys[i]}
        type={LEGEND[i-1]?.name}
        amount={values[i]}
        active = {true}
      />)
    if (window.outerWidth > 1000) categories.push(<br/>)
  }
  if (active) {
    return (
      <Card variant="outlined" 
      sx={(window.outerWidth > 1000) ? { maxWidth: 170, padding: "20px", marginTop:"-20px", marginLeft: "10px", marginRight: "10px"}
       : (window.outerWidth < 768) ? { maxWidth: "160px", padding: "5px", marginTop: "70px", marginBottom: "70px"}
       : { maxWidth: "230px", padding: "5px", marginTop: "70px", marginBottom: "70px"}}> 
      <div style={(window.outerWidth > 1000) ? {} : (window.outerWidth < 768) ? 
      {display: "flex", flexDirection: "row", padding:"5px", flexWrap: "wrap", gap:"10px", maxWidth: "170px", height: "150px"}
      : {display: "flex", flexDirection: "row", padding:"5px", flexWrap: "wrap", gap:"10px", maxWidth: "200px", height: "190px"}}>
        {categories}
      </div>
      </Card>
    );
  }
  return null;
}

// function CustomYAxisTick({legend}) {
  
//         const { x, y, payload } = this.props;
//         return (<Category 
//             current_item={legend[0]}
//             withoutCheckbox={true}
//             key={0}
//             type={legend[0]?.name}></Category>)
// } 

function RechartsDashed({ type, legend, data }) {
  const normalise_data = []
  if (type ==="day") {
    data?.time_intervals?.map((value,key) => {
      //console.log(value)
      let arr = {
        "date":value[0].slice(8,10)+"/"+value[0].slice(5,7),
        "shows":data.data[key][0],
        "clicks":data.data[key][1],
        "spent":data.data[key][2],
        "ctr":data.data[key][3],
        "cpc":data.data[key][4],
        "ecpm":data.data[key][5],
        "cr":data.data[key][6],
        "cpa":data.data[key][7],
      }
      //console.log(arr)
      return normalise_data.push(arr)
    })
  }

  if (type ==="hour") {
    data?.time_intervals?.map((value,key) => {
      console.log(value)
      let arr = {
        "date":value[0].slice(-8,-6)+":"+value[0].slice(-5,-3),
        "shows":data.data[key][0],
        "clicks":data.data[key][1],
        "spent":data.data[key][2],
        "ctr":data.data[key][3],
        "cpc":data.data[key][4],
        "ecpm":data.data[key][5],
        "cr":data.data[key][6],
        "cpa":data.data[key][7],
      }
      return normalise_data.push(arr)
    })
  }

  if (type ==="week") {
    data?.time_intervals?.map((value,key) => {
      let arr = {
        "date":value[0].slice(8,10)+"/"+value[0].slice(5,7)+" - "+value[1].slice(8,10)+"/"+value[1].slice(5,7),
        "shows":data.data[key][0],
        "clicks":data.data[key][1],
        "spent":data.data[key][2],
        "ctr":data.data[key][3],
        "cpc":data.data[key][4],
        "ecpm":data.data[key][5],
        "cr":data.data[key][6],
        "cpa":data.data[key][7],
      }
      return normalise_data.push(arr)
    })
  }

  function numberWithSpaces(number) {
    if(number < 10000) return number
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  //"https://codesandbox.io/s/dashed-line-chart-dxwzg";
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        //width={500}
        height={300}
        data={normalise_data}
        margin={(window.outerWidth > 1000) ? {
          top: 30,
          right: 12,
          left: 12,
          bottom: 5,
        } : {top: 30, right: 40, bottom: 5, left: -3}}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis stroke={"#cccdcc"} tick={{ fill: "#42454c" }} dataKey="date"/>
        <YAxis stroke={"#cccdcc"} tick={{ fill: "#42454c" }} orientation="left" yAxisId={0} tickFormatter={numberWithSpaces}> 
          <Label 
          style={{fill: `${"#3f73f9"}`}}
          angle={(window.outerWidth > 1000) ? -90 : 0} position={(window.outerWidth > 1000) ? "left" : "top"} value={legend[0]?.name}></Label>       
        </YAxis>
        <Tooltip formatter={(value) => numberWithSpaces(value) } content={<CustomTooltip legend={legend} data={normalise_data}/>}/>
        <Line
            yAxisId={0}
            name={legend[0]?.key}
            type="linear"
            dataKey={legend[0]?.key}
            stroke={"#3f73f9"}
            key={legend[0]?.key}
          />
          {(legend.length > 1) ? (
          <YAxis stroke={"#cccdcc"} tick={{ fill: "#42454c" }} orientation="right" yAxisId={1}> 
            <Label style={{fill: `${"#f9143a"}`}} 
            angle={(window.outerWidth > 1000) ? 90 : 0} position={(window.outerWidth > 1000) ? "right" : "top"} value={legend[1]?.name}/>       
          </YAxis>) : ""}
          {(legend.length > 1) ? (<Line
            yAxisId={1}
            name={legend[1]?.key}
            type="linear"
            dataKey={legend[1]?.key}
            stroke={"#f9143a"}
            key={legend[1]?.key}
          />) : ""}
      </LineChart>
    </ResponsiveContainer>
  );
}

export default RechartsDashed;
