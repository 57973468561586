import { useMemo, useState } from "react";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import { useDispatch } from "react-redux";
import moment from "moment";

import "./mediaPlan.scss";
import "../styles/company.css";

import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import RotateLeftIcon from '@mui/icons-material/RotateLeft';

import {
  createNewList,
  updateCampaign,
} from "../../../redux/createNewCompany/actions";
import { enqueueSnackbar } from "../../../redux/notifications/actions";
import RangeDaysPicker from "../../../modules/RangeDaysPicker/RangeDaysPicker";
import { DateModel } from "../../../modules/RangeDaysPicker/dateHelper";
import TooltipedButton from "../../../ui/TooltipedButton/TooltipedButton";
import { setInitialValue } from "../../../utils/helpers";
import { LoadingButton } from "@mui/lab";


const INITIAL_VALUES = {
  enable_desktop: true,
  enable_mobile: true,
  use_holidays_moving: false,
  date_start: null,
  date_end: null,
  channels: {
    mobile_apps: "a",
    social_networks: "a",
  },
  show_schedules: {},
  max_impressions: 13,
  max_banner_impressions: 13,
  max_impressions_uniq: 1,
};

const DataEqual = (objA, objB) => {
  if (!objA || !objB) return false;
  const properties = [
    'date_start',
    'date_end',
    'max_impressions',
    'max_impressions_uniq',
    'max_banner_impressions',
    'channels',
    'show_schedules',
    'enable_mobile',
    'enable_desktop',
  ];
  for (let key of properties) {
    if (key === 'show_schedules') {
      const schedulesA = objA[key] || {}
      const schedulesB = objB[key] || {}
      const daysA = Object.keys(schedulesA)
      const daysB = Object.keys(schedulesB)
      if (daysA.length !== daysB.length) return false
      for (let day of daysA) {
        const hoursA = schedulesA[day] || []
        const hoursB = schedulesB[day] || []
        if (hoursA.length !== hoursB.length) return false
        for (let i = 0; i < hoursA.length; i++) {
          if (hoursA[i] !== hoursB[i]) return false
        }
      }
    } else if (objA[key] !== objB[key]) {
      return false
    }
  }
  return true
}


const MediaPlan = ({ campaignID, handleChange, data, disable }) => {
  const dispatch = useDispatch();
  const [whiteList, setWhiteList] = useState([]);
  const [blackList, setBlackList] = useState([]);
  const [rivalList, setRivalList] = useState([]);
  const [uploadErrors, setErrors] = useState({
    whiteList:'',
    blackList:'',
    rivalList:''
  })
  const [upd, setUpd] = useState(false);
  // const [range, setRange] = useState([]);
  let timeData = useMemo(() => {
    if (data?.show_schedules) {
      const date = new DateModel().setInitData(data?.show_schedules);
      date.setInitFromto(data?.date_start, data?.date_end);
      return date
    }
    return new DateModel().setInitData();
  }, [data?.show_schedules]);  

  const handleDatesClear = () => {
    timeData.days = []
    timeData.hours = []
    timeData.fromTime = null
    timeData.toTime = null
    timeData.listSelectedRanges = []
    timeData.unionDates = []
    timeData.clickedDates = {
      days: { active: [], dates: [] },
      cells: { active: [], dates: [] },
      hours: { active: [], dates: [] }
    }
    setUpd(!upd);
  }
  const isEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  };
  const {
    values,
    handleSubmit,
    handleChange: handleChangeInput,
    isSubmitting,
  } = useFormik({
    initialValues: setInitialValue(data, INITIAL_VALUES),
    
    validate: (values) => {
      let errors = {};
      if (values.date_start > values.date_end) {
        errors.date_start = "Обязательно";
      }
      if (!values.max_impressions) {
        errors.max_impressions = "Обязательно";
      }
      else if ((1 > values.max_impressions ) || (values.max_impressions > 31)) {
        errors.max_impressions = "Может быть от 1 до 31";
      }
      if (!values.max_impressions_uniq) {
        errors.max_impressions_uniq = "Обязательно";
      }
      else if (values.max_impressions_uniq <= 0) {
        errors.max_impressions_uniq = "Может быть только положительным";
      }
      if (!values.max_banner_impressions) {
        errors.max_banner_impressions = "Обязательно";
      }
      else if ((1 > values.max_banner_impressions ) || (values.max_banner_impressions > 31)) {
        errors.max_banner_impressions = "Может быть от 1 до 31";
      }
      if (!values.channels.mobile_apps) {
        errors.channels_mobile_apps = "Обязательно";
      }
      if (!values.channels.social_networks) {
        errors.channels_social_networks = "Обязательно";
      }
      return errors;
    },
    
    onSubmit: async (values) => {
      if (
        !Array.isArray(values.channels.mobile_apps) &&
        !Array.isArray(values.channels.social_networks)
      ) {
        values.channels.mobile_apps = values.channels.mobile_apps.replace(
          /\s+/g,
          ""
        );
        values.channels.social_networks =
          values.channels.social_networks.replace(/\s+/g, "");
        values.channels.mobile_apps = values.channels.mobile_apps.split(",");
        values.channels.social_networks =
          values.channels.social_networks.split(",");
        } 

      if (!isEmpty(whiteList)) {
        dispatch(createNewList(whiteList.file.file, "WHITE", "file"));
      }
      if (!isEmpty(blackList)) {
        dispatch(createNewList(blackList.file.file, "BLACK", "file"));
      }
      if (!isEmpty(rivalList)) {
        dispatch(createNewList(rivalList.file.file, "CONCURRENT", "file"));
      }
      values.show_schedules = timeData.transformToDTO();

      values.date_start = moment(timeData.fromDate).format("YYYY-MM-DD");
      values.date_end = moment(timeData.toDate).format("YYYY-MM-DD");
      
      if (values.show_schedules
        && Object.keys(values.show_schedules).length === 0
        && Object.getPrototypeOf(values.show_schedules) === Object.prototype) {
        dispatch(enqueueSnackbar({
          message: "Заполните расписание показа",
          options: {
            variant: "error",
          },
        }))
        return;
      }

      let summ = 0;
      for (const day in values.show_schedules) {
        summ += values.show_schedules[day].length;
      }

      if (summ < 8) {
        const notifications = "В расписании должно быть не менее 8 часов показа. Сейчас отмечено " + summ
        dispatch(
          enqueueSnackbar({
            message: notifications,
            options: {
              variant: "error",
            },
          })
        );
        return;
      }
      const handleChangeTab = () => handleChange("event", "3")
      if (!DataEqual(data, values)) {
        await dispatch(
          updateCampaign(values, campaignID, "media_plan", handleChangeTab)
        );
      }
      return handleChangeTab()
    },
  });

  return (
    <div className="media-plan__wrapper">
      <div
      className="title-text"
        style={{
          marginBottom: "50px",
        }}
      >
        <h2>Медиа План</h2>
        <p>3000+ Сегментов - Показывайте рекламу Только Тем, Кто Вам Нужен</p>
      </div>
      <form onSubmit={handleSubmit}>
        <Grid
          className="grid"
          container
          spacing={{ xs: 3, md: 3 }}
          columns={{ xs: 2, sm: 8, md: 12 }}
          rowSpacing={5}
          alignItems="center"
          justify="center"
        >
          <Grid item xs={12}>
            <p
              style={{
                height: 24,
                fontWeight: "bold",
                fontSize: 18,
                marginBottom: -20,
              }}
            >
              Параметры размещения
            </p>
          </Grid>
          <Grid item xs={4}>
            <p style={{ textAlign: "center", fontSize: 18 }}>Белый список</p>
            <p style={{ textAlign: "center", fontSize: 12, color:'red',width:406,position:'absolute' }}>{uploadErrors.whiteList}</p>
            <Dropzone
              accept=".csv,.txt,.xls,.xlsx"
              onDrop={(acceptedFiles) =>
                {
                  if(acceptedFiles.length){
                  setErrors(prev => ({...prev, whiteList:''}))
                  setWhiteList((prevFiles) =>
                  acceptedFiles.reduce(
                    (acc, file) => ({
                      ...acc,
                      file: {
                        file,
                        fileType: "",
                        fileName: file.name,
                      },
                    }),
                    prevFiles
                  )
                )
                }else{
                  setErrors(prev => ({...prev, whiteList:'Неверный формат файла'}))
                }
              }
              }
            >
              {({
                getRootProps,
                getInputProps,
                isDragAccept,
                isDragReject,
              }) => (
                <div
                  {...getRootProps({
                    className: `dropzone1
                    ${isDragAccept && "dropzoneAccept1"}
                    ${isDragReject && "dropzoneReject1"}`,
                  })}
                >
                  <input type="file" {...getInputProps()} disabled={disable ? "disabled" : ""} />
                  <div className="media-plan__drag-container">
                    <p style={{ textAlign: "center" }}>Добавить файлы</p>
                    <p style={{ fontSize: "11px" }} className="media-plan__drag-text">
                      Поддерживаются файлы следующих форматов: xls и xlsx -
                      используются данные из первого столбца, csv / txt -
                      разделитель столбцов ; (точка с запятой) и используются
                      данные из первого столбца
                      <br />
                      <span className="media-plan__drag-text">Добавлено: </span>
                      {Object.values(whiteList).map((value, index) => {
                        return (
                          <span key={index} className="media-plan__drag-text">
                            {value.fileName}
                          </span>
                        );
                      })}
                    </p>
                  </div>
                </div>
              )}
            </Dropzone>
          </Grid>

          <Grid item xs={4}>
            <p style={{ textAlign: "center", fontSize: 18 }}>Черный список</p>
            <p style={{ textAlign: "center", fontSize: 12, color:'red',width:406,position:'absolute' }}>{uploadErrors.blackList}</p>
            <Dropzone
              accept=".csv,.txt,.xls,.xlsx"
              onDrop={(acceptedFiles) =>
                {
                  if(acceptedFiles.length){
                  setErrors(prev => ({...prev, blackList:''}))
                  setBlackList((prevFiles) =>
                  acceptedFiles.reduce(
                    (acc, file) => ({
                      ...acc,
                      file: {
                        file,
                        fileType: "",
                        fileName: file.name,
                      },
                    }),
                    prevFiles
                  )
                )
                }else{
                  setErrors(prev => ({...prev, blackList:'Неверный формат файла'}))
                }
              }
              }
            >
              {({
                getRootProps,
                getInputProps,
                isDragAccept,
                isDragReject,
              }) => (
                <div
                  {...getRootProps({
                    className: `dropzone1
                  ${isDragAccept && "dropzoneAccept1"}
                  ${isDragReject && "dropzoneReject1"}`,
                  })}
                >
                  <input type="file" {...getInputProps()} disabled={disable ? "disabled" : ""} />
                  <div className="media-plan__drag-container">
                    <p style={{ textAlign: "center" }}>Добавить файлы</p>
                    <p style={{ fontSize: "11px" }} className="media-plan__drag-text">
                      Поддерживаются файлы следующих форматов: xls и xlsx -
                      используются данные из первого столбца, csv / txt -
                      разделитель столбцов ; (точка с запятой) и используются
                      данные из первого столбца
                      <br />
                      <span className="media-plan__drag-text">Добавлено: </span>
                      {Object.values(blackList).map((value, index) => {
                        return (
                          <span key={index} className="media-plan__drag-text">
                            {value.fileName}
                          </span>
                        );
                      })}
                    </p>
                  </div>
                </div>
              )}
            </Dropzone>
          </Grid>
          <Grid item xs={4}>
            <p style={{ textAlign: "center", fontSize: 18 }}>
              Список сайтов конкурентов
            </p>
            <p style={{ textAlign: "center", fontSize: 12, color:'red',width:406,position:'absolute' }}>{uploadErrors.rivalList}</p>
            <Dropzone
              accept=".csv,.txt,.xls,.xlsx"
              onDrop={(acceptedFiles) =>
                {
                  if(acceptedFiles.length){
                  setErrors(prev => ({...prev, rivalList:''}))
                  setRivalList((prevFiles) =>
                  acceptedFiles.reduce(
                    (acc, file) => ({
                      ...acc,
                      file: {
                        file,
                        fileType: "",
                        fileName: file.name,
                      },
                    }),
                    prevFiles
                  )
                )
                }else{
                  setErrors(prev => ({...prev, rivalList:'Неверный формат файла'}))
                }
              }
              }
            >
              {({
                getRootProps,
                getInputProps,
                isDragAccept,
                isDragReject,
              }) => (
                <div
                  {...getRootProps({
                    className: `dropzone1
                  ${isDragAccept && "dropzoneAccept1"}
                  ${isDragReject && "dropzoneReject1"}`,
                  })}
                >
                  <input type="file" {...getInputProps()} disabled={disable ? "disabled" : ""} />
                  <div className="media-plan__drag-container">
                    <p style={{ textAlign: "center" }}>Добавить файлы</p>
                    <p style={{ fontSize: "11px" }} className="media-plan__drag-text">
                      Поддерживаются файлы следующих форматов: xls и xlsx -
                      используются данные из первого столбца, csv / txt -
                      разделитель столбцов ; (точка с запятой) и используются
                      данные из первого столбца
                      <br />
                      <span className="media-plan__drag-text">Добавлено: </span>
                      {Object.values(rivalList).map((value, index) => {
                        return (
                          <span key={index} className="media-plan__drag-text">
                            {value.fileName}
                          </span>
                        );
                      })}
                    </p>
                  </div>
                </div>
              )}
            </Dropzone>
          </Grid>

          <Grid className="toggle" item xs={4}>
            <p style={{ marginTop: "15px", marginLeft: "10px" }}>
              Тип устройства*
            </p>
            <FormControl fullWidth sx={{ m: 1, width: "100%" }}>
              <FormGroup
                sx={{
                  marginLeft: "20px",
                  color: "black",
                }}
              >
                <div style={{ display: "flex", marginLeft: "-15px" }}>
                  <FormControlLabel
                    disabled={disable}
                    control={<Switch defaultChecked />}
                    label="Мобильные"
                    name="enable_mobile"
                    onChange={handleChangeInput}
                    value={values.enable_mobile}
                  />
                  <FormControlLabel
                    disabled={disable}
                    control={<Switch defaultChecked />}
                    label="Десктопные"
                    name="enable_desktop"
                    onChange={handleChangeInput}
                    sx={{ marginLeft: "10px" }}
                    value={values.enable_desktop}
                  />
                </div>
              </FormGroup>
            </FormControl>
          </Grid>

          <Grid
            item
            xs={4}
            sx={{
              marginTop: "35px",
            }}
          ></Grid>
          <Grid
            container
            spacing={2}
            sx={{
              marginLeft: "25px",
              marginTop: "25px",
            }}
          >
            <RangeDaysPicker disabled={false} timeData={timeData} from={data?.date_start} to={data?.date_end} />
          </Grid>
          <Grid
            container
            sx={{
              marginTop: "50px",
              marginLeft: "25px",
            }}
          >
            <Grid className="back" item xs={4}>
              <Link to="/">
                <Button variant="outlined">Отмена</Button>
              </Link>
            </Grid>

            <Grid container item xs={8} justifyContent="flex-end">
              <TooltipedButton
                title="Не выбран тип устройства"
                isShowTitle={!values.enable_desktop && !values.enable_mobile}
                placement="top"
                renderButton={() => {
                  if (isSubmitting)
                    return (
                      <LoadingButton
                        loading={true}
                        loadingPosition="end"
                        endIcon={<SendIcon />}
                        variant="contained"
                      >
                        Сохранение
                      </LoadingButton>
                    );
                  return (
                    <div style={{display: "flex", gap: "10px"}}>
                      <Button
                        variant="contained"
                        style={{background: "#6c788f", color: "white"}}
                        endIcon={<RotateLeftIcon />}
                        disabled={!values.enable_desktop && !values.enable_mobile}
                        onClick={() => handleDatesClear()}
                      >
                        Сбросить
                      </Button>
                      <Button
                        variant="contained"
                        endIcon={<SendIcon />}
                        disabled={!values.enable_desktop && !values.enable_mobile}
                        onClick={() => handleSubmit()}
                      >
                        Далее
                      </Button>
                    </div>
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default MediaPlan;
