/* eslint-disable react/no-unknown-property */
import  { useEffect, useState } from "react";
import { Button, Circle, ListBox, ListBoxItem, Map, Placemark, YMaps } from "react-yandex-maps";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      maxWidth: 200,
    },
    button : {
        color: theme.palette.error.light
    },
    input: {
        maxWidth:100
    }
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));


const RADIUSES = [
    {
        label: "3км",
        value: 3000,
    },
]

//function deleteRegion(geo_point,selected,setSelected) {}

export default function SegmentMap({ edit = false, open, value, onChange }) {
    const [selected, setSelected] = useState(value);
    const [radius, setRadius] = useState(RADIUSES[0]);
    //const [label,setLabel] = useState("")
    const [isAddingGeoPoint, setIsAddingGeoPoint] = useState(false);
    const [tempGeoPoint, setTempGeoPoint] = useState([0, 0])
    const [message,setMessage] = useState('');
    const [label,setLabel] = useState([])

    useEffect(()=> {
        onChange(label);
    }, [])
    useEffect(() => {
        onChange(selected);
    }, [selected]);
    if (!open) return (<></>)
    const handleMouseMove = (event) => {
        if (!isAddingGeoPoint) {
            return;
        }

        setTempGeoPoint(event.get("coords"));
    }

    const handleMapClick = (event) => {
        if (!isAddingGeoPoint) {
            return;
        }

        const [lat, lng] = event.get("coords");
        setLabel([...label,{checked: false, lat, lng}])
        setSelected([...selected, { label:`${lat},${lng}`, lat, lng, radius: radius.value }])
        setIsAddingGeoPoint(false);
    }

    const handleClickAddGeoPoint = () => {
        setIsAddingGeoPoint(!isAddingGeoPoint);
    }
    const handleRadius = (e, geo_point) => {
        if (e.target.value >= 0.5 && e.target.value<=10){
            geo_point.radius = e.target.value*1000
            const array = selected.filter((value) => (value.lat !== geo_point.lat) && (value.lng !== geo_point.lng));
            setSelected([...array]);
            setSelected([...array, geo_point]);
        }
        else if (e.target.value > 10 ) {
            geo_point.radius = 10*1000
            const array = selected.filter((value) => (value.lat !== geo_point.lat) && (value.lng !== geo_point.lng));
            setSelected([...array]);
            setSelected([...array, geo_point]);
        }
        else {console.log("Radius must be between 0 and 10")}
        //console.log(array)
        //selected.map((value) => {
        //    return ((value.lat !== geo_point.lat) && (value.lng !== geo_point.lng)) ? setSelected({...value, radius: e.target.value*1000}) : console.log("")
        //}){selected?.map((value) => setLabel([...label,{checked: false, lat:value.lat, lng:value.lng}]))}
    }

    function openChangeLabel(e,geo_point) {
        //setLabel(true)
        const array = label?.filter((value) => (value.lat !== geo_point.lat) && (value.lng !== geo_point.lng))
        setLabel([...array]);
        setLabel([...array,{checked: true, lat:geo_point.lat, lng:geo_point.lng}])
        console.log("changed",array)
    }
    const handleChange = event => {
        setMessage(event.target.value);
    }
    function ChangeLabel(geo_point) {
        geo_point.label = message
        //console.log(e.target.value)
        const array = selected.filter((value) => (value.lat !== geo_point.lat) && (value.lng !== geo_point.lng));
        setSelected([...array]);
        setSelected([...array, geo_point]);
        //setLabel(false)
        const array2 = label.filter((value) => (value.lat !== geo_point.lat) && (value.lng !== geo_point.lng))
        setLabel([...array2]);
        setLabel([...array2,{checked: false, lat:geo_point.lat, lng:geo_point.lng}])
        setMessage("")
    }

    function deleteFromSelected(geo_point) { //,selected,setSelected
        const array = selected.filter((value) => (value.lat !== geo_point.lat) && (value.lng !== geo_point.lng));
        setSelected([...array]);
        console.log('Array', array);
        console.log('DeleteSelected');
    }

    return (
        <div >
        <YMaps>
            <Map
                defaultState={{
                    center: [55.75, 37.57],
                    zoom: 4,
                    controls: ['zoomControl', 'fullscreenControl'],
                }}
                modules={[
                    "control.ZoomControl",
                    "control.FullscreenControl",
                ]}
                options={{
                    autoFitToViewport: "always"
                }}
                onClick={handleMapClick}
                onMouseMove={handleMouseMove}
                width="100%"
                //height="100%"
            >
                {!edit && <Button
                    data={{ content: isAddingGeoPoint ? "Отменить добавление" : "Добавить метку" }}
                    options={{ maxWidth: 180, selectOnClick: false }}
                    onClick={(e) => handleClickAddGeoPoint(e)}
                />}
                <ListBox data={{ content: `Радиус (${radius.label})` }} >
                    {
                        RADIUSES.map((rad) => <ListBoxItem
                            key={rad.value}
                            data={{ content: rad.label }}
                            onClick={() => setRadius(rad)}
                            state={{ selected: rad.value === radius.value }}
                        />)
                    }
                </ListBox>
                {isAddingGeoPoint && (
                    <div>
                        <Placemark geometry={tempGeoPoint} onMouseMove={handleMouseMove} onClick={handleMapClick} />
                        <Circle geometry={[tempGeoPoint, radius.value]} options={{ fillColor: "#3F73F925" }} onMouseMove={handleMouseMove} onClick={handleMapClick} />
                    </div>
                )}
                {selected?.map((geo_point) =>
                    <div key={[geo_point.lat, geo_point.lng]}>
                        <Placemark geometry={[geo_point.lat, geo_point.lng]} />
                        <Circle geometry={[[geo_point.lat, geo_point.lng], geo_point.radius]} options={{ fillColor: "#3F73F925" }} />
                    </div>
                )}
            </Map>
        </YMaps >
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 300 }} aria-label="customized table">
                <TableHead>
                <TableRow>
                    <StyledTableCell align="center">Название</StyledTableCell>
                    <StyledTableCell align="center">Радиус</StyledTableCell>
                    <StyledTableCell align="center"></StyledTableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {selected?.sort((a, b) => (a.lat - b.lat)).map((geo_point)=>
                    <StyledTableRow style={{border: "3px solid #818181"}} key={[geo_point.lat, geo_point.lng]}>
                    <StyledTableCell style={{maxWidth: "100%"}}><div align="center">{geo_point.label}
                        {!edit && <button type="button"
                            style={{marginLeft: "5px"}}
                            onClick={(e)=>openChangeLabel(e,geo_point)}>
                            Редактировать
                        </button>}
                        {label?.some((value) => ((value.checked === true) && (value.lat === geo_point.lat) 
                        && (value.lng === geo_point.lng))) &&  
                        (
                        <div style={{marginTop: "10px"}}>
                            <input type="text" style={{border: "3px solid #818181"}} value={message} onChange={handleChange} autoComplete="off" />
                            <button style={{marginLeft: "20px"}} type="button" onClick={()=>ChangeLabel(geo_point)}>
                                Заменить
                            </button>
                        </div>)}
                        {/* : console.log("")*/}
                        </div>
                        </StyledTableCell>
                    <StyledTableCell style={{maxWidth: "100%"}} align="center">
                        {!edit && <input style={{border: "3px solid #818181"}} step="0.5" type="number" autoComplete="off"
                        placeholder={geo_point.radius/1000} min={0.5} max={10} 
                        onChange={(e)=>handleRadius(e, geo_point)} required 
                        />}
                        {edit && <span>{geo_point.radius/1000}</span>}
                        {!edit && <button onClick={(e) => e.preventDefault()}></button>}
                    </StyledTableCell>
                    <StyledTableCell  style={{maxWidth: "100%"}}>
                        {!edit && <button className="button" type="button"
                        onClick={() => deleteFromSelected(geo_point)}>
                            Удалить метку
                        </button>}
                    </StyledTableCell>
                    </StyledTableRow>
                )}
                </TableBody>
            </Table>
        </TableContainer>
        </div>
    )
}
