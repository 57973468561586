import "./SignUp.scss";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { Button, Typography } from "../../ui";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { StylesProvider } from "@material-ui/core/styles";
import { IS_PROD, PUBLIC_URL } from "../../configs/main";
import country_phone from "./CountryPhoneList";
import { isValidPhoneNumber } from 'libphonenumber-js';

import { ReactComponent as LogoWhiteIcon } from "../../assets/images/logo-white.svg";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useState, useEffect } from "react";
import * as Yup from "yup";
import TelephoneInput from "../../widgets/TelephoneInput/TelephoneInput";
import { client } from "../../utils/network";
import ReactFlagsSelect from "react-flags-select";


// const saveDataInLocalStorage = (isSave) => {
//   return localStorage.setItem(
//     "programmatic_save_password",
//     isSave ? "true" : "false"
//   );
// };

function SignUp() {

  // eslint-disable-next-line no-unused-vars
  const [isOfferta, setIsOfferta] = useState(true);
  const [target, setTarget] = useState(false);
  const [country_code, setCountry] = useState("+7");
  const [selected, setSelected] = useState("RU");

  useEffect(()=> {
    country_phone.data.map((item) => item.code === selected ? setCountry(item.dial_code) : "")
  },[selected])


  const [emailConfrim, setEmailConfirm] = useState(false)
  const [count, setCount] = useState(60);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [showRepeatPassword, setShowRepeatPassword] = useState(false);

  const handleClickShowRepeatPassword = () => {
    setShowRepeatPassword(!showRepeatPassword);
  };

  const handleMouseDownRepeatPassword = (event) => {
    event.preventDefault();
  };

  const setButtonsDisabled = (disabled) => {
    for (let button of document.getElementsByClassName('Button')) {
      button.disabled = disabled;
    }
  }

  const timer = () => {
    const id = setInterval(() => {
      setCount((prev) => {
        if (prev === 0) {
          clearInterval(id)
        }
        return prev > 0 ? prev - 1 : 0
      });
    }, 1000)
  }

  useEffect(() => {
    if (emailConfrim) {
      timer();
    }
  }, [emailConfrim])

  const handleClickEmailConfirm = async () => {
    if (count === 0) {
      client.post("/api/v1/auth/email/resend", {
        email: values.email
      })
      setCount(60);
      timer();
    }
  }


  const {
    errors,
    setErrors,
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    touched,
    handleBlur,
  } = useFormik({
    initialValues: {
      first_name: "",
      surname: "",
      email: "",
      phone_number: "",
      role: "DEFAULT",
      password_again: "",
      password: "",
      is_entity: false,
    },
    validationSchema: Yup.object().shape({
      first_name: Yup.string().required("Это обязательное поле")
        .matches(/^[a-zA-Zа-яА-Я\s-]+$/, "Допустимы символы русского или латинского алфaвита, пробелы и дефисы"),
      surname: Yup.string().required("Это обязательное поле")
        .matches(/^[a-zA-Zа-яА-Я\s-]+$/, "Допустимы символы русского или латинского алфaвита, пробелы и дефисы"),
      email: Yup.string().trim()
        .required("Это обязательное поле")
        .matches(/^([\w.-]+)@([\w-]+\.)+([\w]{2,})$/i, "Введен некорректный Email"),
      phone_number: Yup.string().required(
        "Это обязательное поле"
      ),
      password: Yup.string().required("Поле Пароль не может быть пустым")
        // eslint-disable-next-line no-useless-escape
        .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+=\[{\]};:<>|./?,-]).{8,}$/, 'Пароль должен содержать не менее 8 символов, латинские буквы верхнего и нижнего регистра, хотябы одну цифру и специальный символ'),
      password_again: Yup.string().required(
        "Это обязательное поле"
      )
        .oneOf([Yup.ref('password')], 'Пароли не совпадают'),
      role: Yup.string(),
      is_entity: Yup.boolean(),
    }),
    onSubmit: async (values) => {
      setButtonsDisabled(true);
      try {
        values.email = values.email.trim()
        await client.post("/api/v1/auth/registration", values);
        if (IS_PROD === 'true') {
          ym(94151763, 'reachGoal', 'register'); // eslint-disable-line no-undef
        }
        // if (isSavePassword) {
        //   const params = new URLSearchParams();
        //   params.append("username", values.email);
        //   params.append("password", values.password);
        //   const { data } = await client.post("/api/v1/auth/access-token", params);
        //   saveDataInLocalStorage(values.isSavePassword);
        //   localStorage.setItem("token", data.access_token);
        //   const user = await userMutate();
        //   dispatch(setUserData(user));
        //   setEmailConfirm(true)
        // } else {
        //   navigate("/sign-in");
        // }
        setEmailConfirm(true)
      } catch (error) {
        if (error.message === '{"detail":"Пользователь с данным email уже существует"}') {
          setErrors({ ...errors, email: 'Пользователь с таким email уже зарегистрирован' });
        } else if (error.message === '{"detail":"Пользователь с данным номером телефона уже существует"}') {
          setErrors({ ...errors, phone_number: 'Пользователь с таким номером телефона уже зарегистрирован' });
        }else{
          setErrors({ ...errors, response: error.message });
        }
      }
      setButtonsDisabled(false);
    },
  });
console.log(errors);
  const handleInput = (e) => {
    handleChange(e);
    setErrors({});
  };
  // const handleChangeCheckbox = () => {
  //   // setIsSavePassword((prev) => !prev);
  //   setIsOfferta((prev) => !prev);
  //   setErrors({});
  // };

  const handleClick = () => {
    window.open(`${PUBLIC_URL}/publichnyj_dogovor-oferta.pdf`);
  };

  const metricChange = () => {
    if (IS_PROD === 'true') {
      if (!target) {
        setTarget(true);
        ym(94151763, 'reachGoal', 'form_start');  // eslint-disable-line no-undef
      }
    }
  }
  return (
    <div className="SignUp">
      <div className="images">
        <Link to="/">
          <LogoWhiteIcon className="logo" />
        </Link>

        <div className="greetings">
          <Typography className="greetings-title" variant="h1" color="white">
            Приветствуем Вас!
          </Typography>
          <Typography variant="h1" color="white">
            Крупнейшая programmatic-экосистема в Рунете
          </Typography>
        </div>
      </div>
      <form className="wrapp" autoComplete="off" onSubmit={handleSubmit} onChange={metricChange}>
        <div className="form">
          <Typography className="form-title" variant="h1" color="dark-gray">
            Регистрация
          </Typography>
          {!emailConfrim && <Typography className="sub-title" variant="h2" color="dark-gray">
            Всего несколько действий и Вы — участник нашего проекта!
          </Typography>}
          {emailConfrim && <div>
            <Typography className="confirm_text">
              На Ваш электронный адрес было отправлено письмо. Для завершения регистрации откройте его и подтвердите свою почту.
            </Typography>
            <Button type="button" className="btnConfirm" onClick={() => handleClickEmailConfirm()}>
              Отправить еще раз {count === 60 ? "01:00" : count < 10 ? `00:0${count}` : `00:${count}`}
            </Button>
            <Typography className="textLink">
              У вас уже есть аккаунт? <Link className="link" to="/sign-in">Войти</Link>
            </Typography>
          </div>}
          {!emailConfrim && <><form className="signUp-form">
            <div className="validate_input">
              <input
                id="email"
                name="email"
                className={errors.email && touched.email ? "email-field err" : "email-field"}
                type="email"
                placeholder="Email"
                value={values.email.trim()}
                onChange={handleInput}
                onBlur={handleBlur}
              />
              {touched.email && errors.email ? (
                <div className="error_text">{errors.email}</div>
              ) : null}
            </div>
            <div className="validate_input">
              <input
                id="first_name"
                name="first_name"
                className={errors.first_name && touched.first_name ? "name-field err" : "name-field"}
                type="text"
                placeholder="Имя"
                value={values.first_name}
                onChange={handleInput}
                onBlur={handleBlur}
              />
              {touched.first_name && errors.first_name ? (
                <div className="error_text">{errors.first_name}</div>
              ) : null}
            </div>
            <div className="validate_input">
              <input
                id="surname"
                name="surname"
                className={errors.surname && touched.surname ? "name-field err" : "name-field"}
                type="text"
                placeholder="Фамилия"
                value={values.surname}
                onChange={handleInput}
                onBlur={handleBlur}
              />
              {touched.surname && errors.surname ? (
                <div className="error_text">{errors.surname}</div>
              ) : null}
            </div>
            <div className="validate_input">
              <div style={{ display: 'flex' }}>
                <ReactFlagsSelect
                  className={(errors.phone_number && touched.phone_number) || 
                    (touched.phone_number && !isValidPhoneNumber(values.phone_number, selected))
                     ? "flags-select err" : "flags-select"}
                  selected={selected}
                  selectedSize={10}
                  selectButtonClassName="menu-flags-button"
                  onSelect={(code) => setSelected(code)}
                  showSelectedLabel={false}
                  showOptionLabel={false}
                />
                <div className={(errors.phone_number && touched.phone_number) || 
                    (touched.phone_number && !isValidPhoneNumber(values.phone_number, selected))
                     ? "phone_element err" : "phone_element"} >
                  {country_code}
                </div>
                <TelephoneInput
                  id="phone_number"
                  name="phone_number"
                  className={(errors.phone_number && touched.phone_number) || 
                    (touched.phone_number && !isValidPhoneNumber(values.phone_number, selected)) 
                    ? "phone-field err" : "phone-field"}
                  onChange={(e) => setFieldValue('phone_number', `${country_code}${e.target.value}`)}
                  onBlur={handleBlur}
                  value={values.phone_number}
                  // mask={PHONE_MASK}
                />
              </div>
              {touched.phone_number && errors.phone_number ? (
                <div className="error_text">{errors.phone_number}</div>
              ) : null}
              {touched.phone_number && !isValidPhoneNumber(values.phone_number, selected) ?
                <div className="error_text">Введите корректный номер</div>
                : null}
            </div>
            <StylesProvider injectFirst>
              <div className="validate_input">
                <Input
                  disableUnderline={true}
                  autoComplete="new-password"
                  id="password"
                  name="password"
                  className={errors.password && touched.password ? "password-field err" : "password-field"}
                  type={showPassword ? "text" : "password"}
                  placeholder="Пароль"
                  value={values.password}
                  onChange={handleInput}
                  onBlur={handleBlur}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {touched.password && errors.password ? (
                  <div className="error_text">{errors.password}</div>
                ) : null}
              </div>
              {/* <span className="helpfullText">8-64 символа, минимум один символ в нижнем регистре, один символ в верхнем регистре, одна цифра и один спецсимвол</span> */}
              <div className="validate_input">
                <Input
                  disableUnderline={true}
                  id="password_again"
                  name="password_again"
                  className={errors.password_again && touched.password_again ? "password-field err" : "password-field"}
                  type={showRepeatPassword ? "text" : "password"}
                  value={values.password_again}
                  onChange={handleInput}
                  onBlur={handleBlur}
                  placeholder="Подтвердите пароль"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowRepeatPassword}
                        onMouseDown={handleMouseDownRepeatPassword}
                      >
                        {showRepeatPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {touched.password_again && errors.password_again ? (
                  <div className="error_text">{errors.password_again}</div>
                ) : null}
              </div>
            </StylesProvider>
            {/* <div className="save-password">
              <div
                className="remember unselectable"
                onClick={handleChangeCheckbox}
              >
                <Checkbox
                  onChange={handleChangeCheckbox}
                  value={isSavePassword}
                />
                <Typography variant="body1" color="dark-gray">
                  запомнить пароль
                </Typography>
              </div>
            </div> */}
            <RadioGroup
              aria-label="is_entity"
              name="is_entity"
              defaultValue="individual"
              onChange={(e) => {
                handleInput(e);
                setFieldValue("is_entity", !values.is_entity);
              }}
              sx={{
                marginBottom: "10px",
              }}
            >
              <div className="field-radio">
                <FormControlLabel
                  value="individual"
                  control={<Radio />}
                  label="Я физическое лицо"
                />
                <FormControlLabel
                  value="entity"
                  control={<Radio />}
                  label="Я юридическое лицо"
                />
              </div>
            </RadioGroup>
          </form>
            <div className="save-password">
              <div
                className="remember unselectable"
              // onClick={handleChangeCheckbox}
              >
                <div className="offerta">
                  Нажимая кнопку “Зарегистрироваться”, вы принимаете условия
                  <button onClick={handleClick}>
                    оферты
                  </button>
                </div>
              </div>
            </div>
            <div className="btn-actions">
              <Button type="submit" variant="primary" className="primary">
                Зарегистрироваться
              </Button>
              <Link to="/sign-in">
                <Button variant="secondary">Войти</Button>
              </Link>
            </div></>}
        </div>
      </form>
    </div>
  );
}

export default SignUp;
