import { Box, Modal, Typography, TextField } from "@material-ui/core";
import { API_HOST } from "../../../configs/main";
import { useEffect, useState } from "react";
import DataTable from "../Table/Datatable";
import SortPopup from "../SortPopUp/SortPopup";
import { format as dateFormat } from "date-fns";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import "../../../widgets/DateFromTo/DateFromTo.scss";
import { filterDate, sortCampaings } from "./filters";

const style = {
  maxHeight: "600px",
  overflowY: "scroll",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};
const columns = [
  {
    Header: "CTR",
    accessor: "ctr",
    maxWidth: 400,
    minWidth: 80,
    width: 260,
  },
  {
    Header: "Дата",
    accessor: "created_at",
    maxWidth: 400,
    minWidth: 80,
    width: 260,
  }
];


function DateFromTo({
  handleChange,
}) {
  const [value, setValue] = useState(null);

  useEffect(() => handleInput(value), []);

  const handleInput = (fieldValue) => {
    setValue(fieldValue);
    handleChange(fieldValue);
  };
  return (
    <div className="DatePicker">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          disabled={false}
          label="Выберите дату"
          name="fromDate"
          mask="__/__/____"
          value={value}
          inputFormat="dd/MM/yyyy"
          format="dd-MM-yyyy"
          onChange={(value) => {setValue(value); handleInput(value)}}
          views={["day"]}
          renderInput={(params) => (
            <TextField className="controls_item dateFrom" {...params} />
          )}
        />
      </LocalizationProvider>
    </div>
  );
}




export const CtrViewModal = ({ modal, handleCloseEdit, campaing }) => {
  const [filtersValue, setValue] = useState({created_at: ""})
  const [filterArr, setFilter] = useState([]);
  const [update, setUpd] = useState(false)
  const [newCtr, setNewCtr] = useState({
    created_at: "",
    ctr: 0,
    campaign_id: ""
  })

  const handleSetCtr = async (campaign_id) => {

    if (newCtr.created_at == "") {
      return alert("Для добавления CTR нужно обязательно указать дату.")
    }
    if (newCtr.ctr < 0) {
      return alert("CTR не может быть отрицательным.")
    }

    const ctrData = {
      ...newCtr,
      campaign_id: campaign_id
    }

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("admin_token")
    )
    await fetch(`${API_HOST}/api/v1/campaign-ctr/import`, {
      method: "post",
      headers: myHeaders,
      body: JSON.stringify([ctrData])
    })
    .catch(err => console.log(err))
    .then(setUpd(!update))
  }

  useEffect(() => {
    const fetchData = async () => {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("admin_token")
      )
      const response = await fetch(`${API_HOST}/api/v1/campaign-ctr/export/${campaing.id}`, {
        method: "get",
        headers: myHeaders
      })
      const data = await response.json()
      console.log(data)
      setFilter(data)
    }
    setTimeout(() => {
      fetchData()
    }, 500)
  }, [update])

  
  useEffect(() => {
    const result = Object.entries(filtersValue).reduce(
      (acc, [key, value]) => {
        switch (key) {
          case "created_at":
            if (value === "") return acc;
            return filterDate(value, acc);
          case "sort":
            if (value === "") return acc;
            return sortCampaings(value, acc);
          default:
            return acc;
        }
      },
      [...filterArr]
    );
    setFilter(result);
  }, [filtersValue]);

  const handleSortChange = (item) => {
    setValue((prev) => ({ ...prev, sort: item.type }));
  };
  const handleCreatedAtChange = (value) => {
    setNewCtr((prev) => ({ ...prev, created_at: value }))
  };

  function getTableData() {
    if (!filterArr || filterArr.length === 0) {
      return [
        {
          ctr: <div className="td">-</div>,
          created_at: <div className="td">-</div>
        }
      ];
    }
    return filterArr
      .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
      .map((el) => {
        return {
          ctr: <div className="td">{el.ctr !== undefined ? el.ctr : "-"}</div>,
          created_at: <div className="td">{el.created_at ? dateFormat(Date.parse(el.created_at), "dd/MM/yyyy") : "-"}</div>
        };
      });
  }
  return (
    <Modal
      open={modal}
      onClose={handleCloseEdit}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography style={{ marginBottom: "15px"}} id="modal-modal-title" variant="h6" component="h2">
          CTR Кампании
        </Typography>
        <Typography
          id="modal-modal-description"
          style={{
            marginBottom: 20,
            marginTop: 20,
            color: "#42454C",
            opacity: "70%",
          }}
        >
          Название кампании: {campaing?.name}<br/>
          Почта клиента: {campaing?.campaign_creator_email}
        </Typography>
        <div style={{display: "flex", justifyContent: "flex-end", alignItems: "end", gap: "25px", marginBottom: 20}}>
          <TextField label="Укажите ctr" type="number"
            onInput={(e) => setNewCtr((prev) => ({...prev, ctr: e.target.value}))}/>
          <div className="DatePicker">
            <DateFromTo handleChange={handleCreatedAtChange}/>
          </div>
          <button 
            onClick={() => handleSetCtr(campaing?.id)}
            style={{height: "fit-content", padding: "8px 15px", width: "150px", fontSize: "14px", fontWeight: "500", borderRadius: 5, background: "#5974ff", color: "#fff"}}>
            Добавить CTR
          </button>
        </div>
        <SortPopup
          sortedBy={true}
          title="Сортировать по"
          items={[
            { name: "дате создания", type: "created_at" },
          ]}
          onChange={handleSortChange}
        />
        <DataTable data={getTableData()} columns={columns} />
      </Box>
    </Modal>
  )
}